var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team agileinfo"},[_c('div',{staticClass:"container co-text"},[_c('h3',{staticClass:"agileits-title",staticStyle:{"margin-bottom":"1em"}},[_vm._v(" 《灯塔伙伴系统》使用步骤 ")]),_vm._m(0),_vm._m(1),_c('p',[_vm._v(" 2. 注册完成，机构通过手机和密码登录系统，系统会自动生成合作机构的“测评二维码”（该二维码在推广机构入口页，是和机构唯一身份认证，机构发展的测评用户通过该二维码进行测评）。 ")]),_vm._m(2),_c('p',[_vm._v(" 3. 测评用户通过微信扫描“测评二维码”直接进入测评页面进行测评，并生相应的成测评报告。 ")]),_vm._m(3),_c('p',[_vm._v(" 4. 合作机构可通过系统查看通过“测评二维码”进行测评的用户详细情况，包括是否登录，是否完成测评，是否付费，购买了哪类测评产品等信息。 ")]),_vm._m(4),_vm._m(5),_vm._m(6),_c('p',{staticStyle:{"text-align":"center"}},[_c('a',{staticClass:"button button-isi",on:{"click":function($event){return _vm.jumpPage('HomeIndexNewCooperation')}}},[_c('i',{staticClass:"el-icon-back"}),_c('span',[_vm._v("  返回")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 1. 首先，合作机构可以"),_c('a',{attrs:{"href":"https://career-explore.com/institution"}},[_c('strong',[_vm._v("点击此处进入机构注册系统")])]),_vm._v("，进行注册（使用手机号进行注册）。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("./../../../../Public/resources/images/templates/home/index/newco_inst_1.min.png"),"alt":"","width":"500"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("./../../../../Public/resources/images/templates/home/index/newco_inst_2.min.png"),"alt":"","width":"300"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("./../../../../Public/utils/new-index/images/co1.jpg"),"alt":"","width":"300"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("./../../../../Public/utils/new-index/images/co2.png"),"alt":"","width":"500"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("./../../../../Public/utils/new-index/images/co3.png"),"alt":"","width":"500"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 5. 测评用户的付费信息作为合作机构的提成依据，按照人头进行业务提成，提成比例参考“"),_c('a',{attrs:{"href":"/Home/Index/newcooperation_proxy"}},[_c('strong',[_vm._v("产品代理合作协议")])]),_vm._v("”。 ")])
}]

export { render, staticRenderFns }