<template>
    <!-- about-->
    <div class="team agileinfo">
      <div class="container co-text">
        <h3 class="agileits-title" style="margin-bottom: 1em;">
          《灯塔伙伴系统》使用步骤
        </h3>
        <p>
          1. 首先，合作机构可以<a href="https://career-explore.com/institution"
            ><strong>点击此处进入机构注册系统</strong></a
          >，进行注册（使用手机号进行注册）。
        </p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/resources/images/templates/home/index/newco_inst_1.min.png"
            alt=""
            width="500"
          />
        </p>
        <p>
          2.
          注册完成，机构通过手机和密码登录系统，系统会自动生成合作机构的“测评二维码”（该二维码在推广机构入口页，是和机构唯一身份认证，机构发展的测评用户通过该二维码进行测评）。
        </p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/resources/images/templates/home/index/newco_inst_2.min.png"
            alt=""
            width="300"
          />
        </p>
        <p>
          3.
          测评用户通过微信扫描“测评二维码”直接进入测评页面进行测评，并生相应的成测评报告。
        </p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/utils/new-index/images/co1.jpg"
            alt=""
            width="300"
          />
        </p>
        <p>
          4.
          合作机构可通过系统查看通过“测评二维码”进行测评的用户详细情况，包括是否登录，是否完成测评，是否付费，购买了哪类测评产品等信息。
        </p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/utils/new-index/images/co2.png"
            alt=""
            width="500"
          />
        </p>
        <p style="text-align: center;">
          <img
            src="./../../../../Public/utils/new-index/images/co3.png"
            alt=""
            width="500"
          />
        </p>
        <p>
          5.
          测评用户的付费信息作为合作机构的提成依据，按照人头进行业务提成，提成比例参考“<a
            href="/Home/Index/newcooperation_proxy"
            ><strong>产品代理合作协议</strong></a
          >”。
        </p>
        <p style="text-align:center;">
          <a
            class="button button-isi"
            @click="jumpPage('HomeIndexNewCooperation')"
            ><i class="el-icon-back"></i><span>&nbsp;&nbsp;返回</span></a
          >
        </p>
      </div>
    </div>
    <!-- //about-team-->
</template>

<script>
export default {
  name: 'HomeIndexNewCooperationInst',
  data: function () {
    return {}
  },
  mounted: function () {},
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
